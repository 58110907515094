div[role='combobox'] {
	label ~ div {
		border: solid #eee;

		background-color: var(--white);
	}
}

ul[role='listbox'] {
	background-color: var(--white);
}
