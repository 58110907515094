$gr-1: linear-gradient(170deg, #01e4f8 0%, #1d3ede 100%);
$gr-2: linear-gradient(170deg, #b4ec51 0%, #429321 100%);
$gr-3: linear-gradient(170deg, #c86dd7 0%, #3023ae 100%);

.gr-1 {
	background: $gr-1;
}

.gr-2 {
	background: $gr-2;
}

.gr-3 {
	background: $gr-3;
}

.h-100vh {
	height: 100% !important;
}

.align-middle {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/* Stepper */
.steps {
	display: flex;
	justify-content: space-between;
	align-items: center;
	min-height: 4rem;
	margin: 0 0 2rem 0;
}

.step {
	display: flex;
	align-items: center;
	cursor: pointer;
	padding: 1rem 0.5rem;
	transition: 0.5s;
	border-radius: 4px;
	user-select: none;
}

.step:active {
	background-color: rgba(0, 0, 0, 0.2);
	transition: 0.02s;
}

.step:active .label {
	color: rgb(29, 29, 29);
}

.step .icon {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 2.3rem;
	height: 2.3rem;
	background-color: var(--secondary);
	color: var(--primary-text);
	margin-right: 0.5rem;
}

.step.active .icon,
.step.complete .icon {
	background-color: var(--primary);
}

.step-divider {
	flex: 1;
	border-top-style: solid;
	border-color: #bdbdbd;
	border-top-width: 1px;
}

.step .label {
	color: rgb(180, 180, 180);
	margin-left: 0.25rem;
}

.step.active .label {
	color: rgb(29, 29, 29);
}

.column {
	margin-top: 3rem;
	cursor: pointer;

	&.selected {
		.card {
			border: 2px solid #28a745;
		}
	}

	&:hover {
		padding-left: 0;

		.card .txt {
			margin-left: 1rem;

			h1,
			p {
				color: rgba(255, 255, 255, 1);
				opacity: 1;
			}
		}

		span {
			color: rgba(255, 255, 255, 1);

			&:after {
				width: 10%;
			}
		}
	}
}

.builder-card {
	* {
		transition: 0.5s;
	}

	min-height: 170px;
	margin: 0;
	padding: 1.7rem 1.2rem;
	border: none;
	border-radius: 0;
	color: rgba(0, 0, 0, 1);
	letter-spacing: 0.05rem;
	font-family: 'Oswald', sans-serif;
	box-shadow: 0 0 21px rgba(0, 0, 0, 0.27);

	.txt {
		z-index: 1;

		h1 {
			font-size: 1.5rem;
			font-weight: 300;
			text-transform: uppercase;
		}

		p {
			font-size: 0.7rem;
			font-family: 'Open Sans', sans-serif;
			letter-spacing: 0rem;
			margin-top: 33px;
			opacity: 0;
			color: rgba(255, 255, 255, 1);
		}
	}

	span {
		z-index: 3;
		font-size: 0.7rem;
		color: rgba(0, 0, 0, 1);
		margin-left: 1rem;
		position: relative;
		bottom: -0.5rem;
		text-transform: uppercase;

		&:after {
			content: '';
			display: inline-block;
			height: 0.5em;
			width: 0;
			margin-right: -100%;
			margin-left: 10px;
			border-top: 1px solid rgba(255, 255, 255, 1);
			transition: 0.5s;
		}
	}

	.ico-card {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	i {
		position: relative;
		right: -50%;
		top: 60%;
		font-size: 12rem;
		line-height: 0;
		opacity: 0.2;
		color: rgba(255, 255, 255, 1);
		z-index: 0;
	}
}

// new progressive page card Styles

.step-card.card {
	.card-header {
		padding: 1.25rem;
	}
	&.step-1 {
		.card-body {
			button {
				margin-right: 1rem;
			}
		}
	}
}
